import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../common-service/auth.service';

import { Observable } from 'rxjs';

@Component({
  selector: 'app-auth-overlay',
  templateUrl: './auth-overlay.component.html',
  styleUrls: ['./auth-overlay.component.scss']
})
export class AuthOverlayComponent implements OnInit {


  public userDisplayName: string;
  public profileImageUrl: string;
  public userEmail: string;

  constructor(private authService: AuthService,
                private zone: NgZone,
                private router: Router
            ) {
    // this.authService.getOwnSellerList().subscribe((ownSellerList: Seller[]) => {
    //     this.ownSellerList = ownSellerList;
    //     console.log(ownSellerList);
    // });
    this.authService.authData.subscribe((authData) => {
        this.zone.run(() => {
            if (authData) {
                this.userDisplayName = authData.name;
                this.profileImageUrl = authData.profileImageUrl;
                this.userEmail = authData.email;
            }
        });
    }, error => {
        console.log(error);
    });
  }

  switchUser() {
      this.authService.googleSignIn();
      this.router.navigate(['/home']);
      window.location.reload();
  }

  signOut() {
      this.authService.signOut();
      this.router.navigate(['/home']);
      window.location.reload();
  }

  ngOnInit() {
  }

}

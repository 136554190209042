import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { AuthOverlayComponent } from './auth-overlay/auth-overlay.component';
import { AuthControlComponent } from './auth-control/auth-control.component';
import { AuthProfileButtonComponent } from './auth-profile-button/auth-profile-button.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { GoogleSignInComponent } from './google-sign-in/google-sign-in.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    OverlayModule,
    PortalModule,
    MatCardModule,
    MatListModule,
    RouterModule,
  ],
  declarations: [AuthOverlayComponent, AuthControlComponent, AuthProfileButtonComponent, GoogleSignInComponent],
  entryComponents: [AuthOverlayComponent],
  providers: [MatIconRegistry],
  exports: [AuthControlComponent]
})
export class AuthModule { }

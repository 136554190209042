import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { User } from '../../models/user.model';
import { AppErrorHandlerService } from './app-error-handler.service';
import * as _ from 'lodash';

declare var gapi: any;
export interface GoogleUser {
    getAuthResponse(): {
        id_token: string
    };
    getBasicProfile(): {
        getImageUrl(): string;
    };
}

export interface Auth2 {
    currentUser: {
        listen(listener): null;
        get(): GoogleUser;
    };
    isSignedIn: {
        listen(listener): null;
        get(): boolean;
    };
    signIn();
    init(): Promise<Auth2>;
    signOut();
    disconnect();
}

export interface AuthData {
    readonly _id: string;
    readonly name: string;
    readonly email: string;
    readonly seller: string;
    readonly sellername: string;
    readonly shop_category: string;
    readonly permissions: [string];
    readonly jwt: string;
    readonly profileImageUrl: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
    private auth2: Promise<Auth2>;
    public authData: BehaviorSubject<AuthData>; // This sends null in case the user is not signed in
    private jwtHelper = new JwtHelperService();

    constructor(private httpClient: HttpClient, private appErrorHandlerService: AppErrorHandlerService) {
        this.authData = new BehaviorSubject<AuthData>(null);
        this.auth2 = new Promise((resolve, reject) => {
            gapi.load('auth2', () => {
                gapi.auth2.init().then(auth2 => resolve(auth2), reject);
            });
        });

        // This sets up a listener to the change of the googleUse. So everytime
        // switches the user, it reauths with the backend. We also need to check
        // for the current seller after that and fetch the seller permissions for the current seller
        this.isGoogleUserChanged().subscribe((googleUser) => {
            this.backendSignIn(googleUser).subscribe(
                (authData: AuthData) => {
                    console.log(authData);
                    localStorage.setItem('authData', JSON.stringify(authData));
                    if (!authData) {
                        return;
                    } else if (authData.seller) {
                        this.backendGetPermissions(authData.jwt, authData.seller).subscribe((sellerAuthData: AuthData) => {
                            const combinedAuthData = {
                                _id: sellerAuthData._id,
                                name: sellerAuthData.name,
                                seller: sellerAuthData.seller,
                                email: authData.email,
                                jwt: sellerAuthData.jwt,
                                sellername: sellerAuthData.sellername,
                                shop_category: sellerAuthData.shop_category,
                                permissions: sellerAuthData.permissions,
                                profileImageUrl: authData.profileImageUrl,
                            };
                            localStorage.setItem('authData', JSON.stringify(combinedAuthData));
                            this.authData.next(combinedAuthData);
                        },
                         (err) => {console.log(err); this.authData.next(authData); });
                    } else {
                        this.authData.next(authData);
                    }
                }, (err) => {
                    this.appErrorHandlerService.publishError({
                        level: 'ERROR',
                        message: err.error,
                        category: 'http',
                        error: err
                    });
                    this.authData.next(null); // User is not signed in
                }
            );
        });

        this.authData.next(JSON.parse(localStorage.getItem('authData'))); // Last session jwt
    }

    backendSignIn(googleUser: GoogleUser): Observable<AuthData> {
        const payload = {
            id_token: googleUser.getAuthResponse().id_token
        };
        return this.httpClient.post<any>('/admin/v1/auth/sign_in', payload).pipe(map<any, AuthData>(res => {
            localStorage.setItem('user', JSON.stringify(res.user));
            return {
                _id: res.user._id,
                name: res.user.name,
                email: res.user.email,
                seller: res.user.current_seller,
                jwt: res.jwt,
                sellername: null,
                shop_category: null,
                permissions: null,
                profileImageUrl: googleUser.getBasicProfile().getImageUrl(),
            };
        }));
    }

    backendGetPermissions(jwt: string, seller_id: string): Observable<AuthData> {
        const httpOptions = {
            'headers': new HttpHeaders({
                        Authorization: jwt
            }), 'params': {
                seller: seller_id
            }
        };
        return this.httpClient.get<any>('/v1/manage/seller/ownseller/get_permissions', httpOptions).pipe(map<any, AuthData>(res => {
            console.log(res);
            const decodedJwt = this.jwtHelper.decodeToken(res.jwt);
            return {
                _id: decodedJwt._id,
                name: decodedJwt.name,
                seller: decodedJwt.seller,
                email: null,
                jwt: res.jwt,
                sellername: decodedJwt.sellername,
                shop_category: decodedJwt.shop_category,
                permissions: decodedJwt.permissions,
                profileImageUrl: null,
            };
        }));
    }

    googleSignIn(): Promise<GoogleUser> {
        return new Promise((resolve, reject) => {
            this.auth2.then(auth2 => {
                auth2.signIn().then(resolve, (error) => {
                    this.appErrorHandlerService.publishError({
                        level: 'INFO',
                        message: 'Please sign in using google account for using invoice capsule',
                        category: 'user',
                        duration: 3000,
                        error: error
                    });
                    return reject(error);
                });
            });
        });
    }

    isGoogleSignedInChanged(): Observable<boolean> {
        return new Observable<boolean>((observer) => {
            this.auth2.then(auth2 => {
                auth2.isSignedIn.listen(isSignedIn => {
                    observer.next(isSignedIn);
                });
            }, err => {
                observer.error(err);
            });
            return {unsubscribe() { }};
       });
    }


    isGoogleUserChanged(): Observable<GoogleUser> {
        return new Observable<GoogleUser>((observer) => {
            this.auth2.then(auth2 => {
                auth2.currentUser.listen(currentUser => {
                    observer.next(currentUser);
                });
            }, err => {
                observer.error(err);
            });
            return {unsubscribe() { }};
       });
    }

    signOut() {
        this.auth2.then(auth2 => {
            auth2.signOut();
            auth2.disconnect();
            localStorage.clear();
            this.authData.next(null);
        });
    }

    getUser() {
        console.log(localStorage.getItem('user'));
      return JSON.parse(localStorage.getItem('user'));
    }

    getToken() {
      return localStorage.getItem('authData') && JSON.parse(localStorage.getItem('authData')).jwt;
    }

    getDecodeToken() {
   //      console.log(this.jwtHelper.decodeToken(this.getToken()));
        return this.jwtHelper.decodeToken(this.getToken());
    }

    loggedIn() {
    //  console.log("token not expired: "+ tokenNotExpired())
    const token: string = this.getToken();

            if (!token) {
              return false;
            }

            const tokenExpired: boolean = this.jwtHelper.isTokenExpired(token);

            return !tokenExpired;

    }

    getVisibility(role) {
        if (localStorage.getItem('authData') === null) {
            return false;
        }
        if (this.getDecodeToken().permissions && this.getDecodeToken().permissions.indexOf('owner') > -1) {
            return true;
        } else {
            return this.getDecodeToken().permissions && this.getDecodeToken().permissions.indexOf(role) > -1;
        }
    }

}

import { NgModule, ErrorHandler} from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from './auth.service';
import { AuthGuard } from './auth.guard';
import { CanDeactivateGuard } from './can-deactivate-guard.service';

import { HttpClientModule } from '@angular/common/http';
import { AppErrorHandlerService } from './app-error-handler.service';
// import { PartyService } from './party.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  declarations: [],
  providers: [
      AuthService,
       {provide: ErrorHandler, useClass: AppErrorHandlerService},
       AuthGuard,
       CanDeactivateGuard
     //  PartyService
   ]
})
export class CommonServiceModule { }

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [{
  path: 'home',
  loadChildren: 'src/app/home/home.module#HomeModule'
}, {
  path: 'payment',
  loadChildren: 'src/app/payment/payment.module#PaymentModule'
},
{
  path: 'seller',
  loadChildren: 'src/app/seller/seller.module#SellerModule'
},
{
  path: '',
  redirectTo: 'home',
  pathMatch: 'full'
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

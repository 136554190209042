import { Component, OnInit, NgZone, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from '../../common-service/auth.service';
import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { AuthOverlayComponent } from '../auth-overlay/auth-overlay.component';

@Component({
  selector: 'app-auth-control',
  templateUrl: './auth-control.component.html',
  styleUrls: ['./auth-control.component.scss']
})
export class AuthControlComponent implements OnInit {
  public showSignInButton: boolean;
  public showProfileButton: boolean;

  private userName: string;
  private currentSellerName: string;
  public overlayRef: OverlayRef;

  @ViewChild('authProfileButton') authProfileButton: ElementRef;

  constructor(private authService: AuthService,
    private zone: NgZone,
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder) {
    this.authService.authData.subscribe((authData) => {
        this.zone.run(() => {
            if (authData) {
                this.showSignInButton = false;
                this.showProfileButton = true;
                if (authData.name) {
                    this.userName = authData.name;
                }
                if (authData.sellername) {
                    this.currentSellerName = authData.sellername;
                }
            } else {
                this.showSignInButton = true;
                this.showProfileButton = false;
                this.closeOverlay();
            }
        });
    });
  }

  closeOverlay() {
      if (this.overlayRef) {
        this.overlayRef.dispose();
        delete this.overlayRef;
      }

  }
  openAuthOverlay() {
    if (this.overlayRef) {
      return;
    }
    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      positionStrategy: this.overlay.position().flexibleConnectedTo(this.authProfileButton.nativeElement).withPositions([{
        originX: 'end',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'top'
      }])
    });
    const portal = new ComponentPortal(AuthOverlayComponent);
    this.overlayRef.attach(portal);
    this.overlayRef.backdropClick().subscribe(() => {
      this.closeOverlay();
    });
  }
  ngOnInit() {
  }

}
